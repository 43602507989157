/*  https://www.figma.com/file/WAVEDfY1nZuRpUFl9ZpCwQ/%F0%9F%9A%A7-tokens-design-system-%E2%86%92-In-progress?type=design&node-id=56-1544&mode=design&t=l3MQpewHRLNBrmzj-4 */

@use '../colors/colors' as colors;

@mixin button-white {
  background-color: colors.$bg-primary;
  box-shadow: 0px 5px 20px 0px rgba(53, 53, 53, 0.15);
}

@mixin button-primary {
  background-color: colors.$bg-profilum-primary;
  box-shadow: 0px 5px 20px 0px rgba(147, 117, 251, 0.30);
}

@mixin button-secondary {
  background-color: colors.$bg-positive;
  box-shadow: 0px 5px 20px 0px rgba(54, 202, 117, 0.30);
}

@mixin elements-white{
  background-color: colors.$bg-primary;
  box-shadow: 2px 2px 15px 0px rgba(53, 53, 53, 0.08);
}

@mixin content-shadow {
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
}
