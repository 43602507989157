/* https://www.figma.com/file/WAVEDfY1nZuRpUFl9ZpCwQ/%F0%9F%9A%A7-tokens-design-system-%E2%86%92-In-progress?type=design&node-id=1-3345&mode=dev */
@use '../colors/colors' as colors;
@use '../base/variables' as variables;
@use '../functions/functions' as functions;

@mixin text-default {
  color: colors.$text-primary;
  font-family: variables.$avenir-next;
  font-size: functions.pixels-to-rem(16);
  font-style: normal;
  line-height: 24px;
}

@mixin text-default-bold {
  @include text-default;
  font-weight: 700;
}

@mixin text-default-demibold {
  @include text-default;
  font-weight: 600;
}

@mixin text-default-medium {
  @include text-default;
  font-weight: 500;
}

@mixin text-small-default {
  color: colors.$text-primary;
  font-family: variables.$avenir-next;
  font-size: functions.pixels-to-rem(14);
  font-style: normal;
  line-height: 20px;
}

@mixin text-small-bold {
  @include text-small-default;
  font-weight: 700;
}

@mixin text-small-demibold {
  @include text-small-default;
  font-weight: 600;
}

@mixin text-small-medium {
  @include text-small-default;
  font-weight: 500;
}

/* ----------------------------------------------------------- */

@mixin caption-small-default {
  color: colors.$text-primary;
  font-family: variables.$avenir-next;
  font-size: functions.pixels-to-rem(12);
  font-style: normal;
  line-height: 16px;
}

@mixin caption-caps-bold {
  @include caption-small-default;
  font-weight: 700;
  text-transform: uppercase;
}

@mixin caption-caps-medium {
  @include caption-small-default;
  font-weight: 500;
  text-transform: uppercase;
}

@mixin caption-small {
  @include caption-small-default;
  font-weight: 500;
}
