$break-mobile-min: 450px;
$break-mobile-max: 768px;

$break-tablet-min: 769px;
$break-tablet-max: 1024px;

$break-desktop-min: 1025px;

$break-desktop-small-min: 1025px;
$break-desktop-small-max: 1280px;

$break-desktop-medium-min: 1281px;
$break-desktop-medium-max: 1728px;
$break-desktop-large-min: 1729px;

@mixin break-small-mobile() {
  @media screen and (max-width: $break-mobile-min) {
    @content;
  }
}

@mixin only-mobile() {
  @media screen and (max-width: $break-mobile-max - 1px) {
    @content;
  }
}

@mixin break-mobile() {
  @media screen and (max-width: $break-mobile-max) {
    @content;
  }
}

@mixin break-tablet() {
  @media screen and (min-width: $break-tablet-min) and (max-width: $break-tablet-max) {
    @content;
  }
}

@mixin break-desktop() {
  @media screen and (min-width: $break-desktop-min) {
    @content;
  }
}

@mixin break-desktop-small() {
  @media screen and (min-width: $break-desktop-small-min) and (max-width: $break-desktop-small-max) {
    @content;
  }
}

@mixin break-desktop-medium() {
  @media screen and (min-width: $break-desktop-medium-min) and (max-width: $break-desktop-medium-max) {
    @content;
  }
}

@mixin break-desktop-large() {
  @media screen and (min-width: $break-desktop-large-min) {
    @content;
  }
}

@mixin break-mobile-gte() {
  @content;
}

@mixin break-tablet-lte() {
  @media screen and (max-width: $break-tablet-max) {
    @content;
  }
}

@mixin break-tablet-gte() {
  @media screen and (min-width: $break-tablet-min) {
    @content;
  }
}

@mixin break-desktop-lte() {
  @content;
}

@mixin break-desktop-small-lte() {
  @media screen and (max-width: $break-desktop-small-max) {
    @content;
  }
}

@mixin break-desktop-small-gte() {
  @media screen and (min-width: $break-desktop-small-min) {
    @content;
  }
}

@mixin break-desktop-medium-lte() {
  @media screen and (max-width: $break-desktop-medium-max) {
    @content;
  }
}

@mixin break-desktop-medium-gte() {
  @media screen and (min-width: $break-desktop-medium-min) {
    @content;
  }
}

@mixin break-desktop-large-lte() {
  @content;
}
