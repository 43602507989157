/* https://www.figma.com/file/WAVEDfY1nZuRpUFl9ZpCwQ/%F0%9F%9A%A7-tokens-design-system-%E2%86%92-In-progress?type=design&node-id=1-329&mode=design&t=QXuHCMZiSCAeJiIz-4 */

$divider: var(--divider, #eaeaea);
$divider-hover: var(--divider-hover, #cecece);
$divider-active: var(--divider-active, #b2b2b2);
$divider-disabled: var(--divider-disabled, #eaeaea);

$divider-inverse: var(--divider-inverse, #fff);
$divider-inverse-hover: var(--divider-inverse-hover, #f5f5f5);
$divider-inverse-active: var(--divider-inverse-active, #ebebeb);

$divider-profilum: var(--divider-profilum, #6a52ff);
$divider-profilum-hover: var(--divider-profilum-hover, #513ec2);
$divider-profilum-active: var(--divider-profilum-active, #4434a3);

$divider-positive: var(--divider-positive, #36ca75);
$divider-positive-hover: var(--divider-positive-hover, #299959);
$divider-positive-active: var(--divider-positive-active, #23814b);

$divider-favorites: var(--divider-favorites, #0cab72);
$divider-favorites-hover: var(--divider-favorites-hover, #098257);
$divider-favorites-active: var(--divider-favorites-active, #086d49);

$divider-negative: var(--divider-negative, #f05f4c);
$divider-negative-hover: var(--divider-negative-hover, #b6483a);
$divider-negative-active: var(--divider-negative-active, #9a3d31);

$divider-interests: var(--divider-interests, #3a81ed);
$divider-interests-hover: var(--divider-interests-hover, #2c62b4);
$divider-interests-active: var(--divider-interests-active, #255298);

$divider-skills: var(--divider-skills, #f87c23);
$divider-skills-hover: var(--divider-skills-hover, #bc5e1b);
$divider-skills-active: var(--divider-skills-active, #9f4f16);
