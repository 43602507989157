.prf-modal-container .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 16px !important;
}

.prf-table .mat-mdc-table .mat-mdc-cell.prf-center,
.prf-table .mat-mdc-table .mat-mdc-header-cell.prf-center {
  text-align: center !important;
}

.prf-table .mat-mdc-table .prf-highlight .mat-mdc-cell:first-child {
  position: relative;

  &::before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #f05f4c;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 17px;
  }
}
