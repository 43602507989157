$mulish: Mulish, sans-serif;
$inter: Inter, sans-serif;
$avenir-next: Avenirnextcyr, sans-serif;
$alsschlangesans: ALSSchlangesans, sans-serif;
$unbounded: Unbounded, sans-serif;
$geologica: Geologica, sans-serif;
$cofo-sans: 'CoFo Sans', sans-serif;

$bvb-test-font: $mulish;
$muf-font: $inter;

$BVB_APPROBATION_LITE: 'BVB_APPROBATION_LITE';
$BVB_TEST: 'BVB_TEST';
$BVB_TEST_OPEN_CONTOUR: 'BVB_TEST_OPEN_CONTOUR';
$BVB_TEST_PARENT: 'BVB_TEST_PARENT';
$BVB_TEST_RAS: 'BVB_TEST_RAS';
$BVB_TEST_VISUALLY_IMPAIRED: 'BVB_TEST_VISUALLY_IMPAIRED';
$MUF: 'MUF';
$MY_FUTURE: 'MY_FUTURE';
$MY_TALENTS: 'MY_TALENTS';
$PREPARING_FOR_THE_FUTURE: 'PREPARING_FOR_THE_FUTURE';
$PROTVORCHESTVO: 'PROTVORCHESTVO';
$MINCIFRY_TEST: 'MINCIFRY_TEST';
$SOUTH_AFRICA: 'SOUTH_AFRICA';
$MY_HORIZONS: 'MY_HORIZONS';
