@use 'libs/profilum-components/src/lib/styles/styles' as prf-lib;

.prf-school-statistics-table {
  grid-template-columns: 280px 191px 191px 191px 191px 191px 191px;

  @include prf-lib.only-mobile {
    grid-template-columns: 160px 175px 175px 175px 175px 175px 175px;
  }

  th, td {
    align-content: center;
  }

  thead {
    th {
      text-wrap: wrap;

      &:first-child {
        background: #fff;
        position: -webkit-sticky;
        position: sticky;
        border-right: 2px solid #EAEAEA;
        left: 0;

        &::after {
          content: '';
          position: absolute;
          right: -4px;
          top: 0;
          width: 4px;
          height: 100%;
          background-color: #eaeaea;
          filter: blur(2px);
        }

        @include prf-lib.only-mobile {
          min-width: 160px;
        }
      }

      &:nth-child(2) {
        left: 280px;

        @include prf-lib.only-mobile {
          left: 160px;
        }
      }

      .current-lesson {
        color: prf-lib.$text-tertiary;

        @include prf-lib.only-mobile {
          font-size: 14px;
        }
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;

      @include prf-lib.break-desktop {
        &:hover {
          td {
            background-color: prf-lib.$bg-primary-talent;
          }
        }
      }

      td {
        &:first-child {
          position: -webkit-sticky;
          position: sticky;
          min-width: 280px;
          border-right: 2px solid #EAEAEA;
          left: 0;

          @include prf-lib.only-mobile {
            min-width: 160px;
          }

          &::after {
            content: '';
            position: absolute;
            right: -4px;
            top: 0;
            width: 4px;
            height: 100%;
            background-color: #eaeaea;
            filter: blur(2px);
          }
        }

        &.class-cell {
          .wrapper {
            display: flex;
            flex-direction: column;
          }

          .class-name,
          .teacher-name {
            @include prf-lib.text-default-medium;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-bottom: prf-lib.$spacing-x1;
            transition: color .15s linear;

            @include prf-lib.only-mobile {
              font-size: 14px;
            }
          }
        }

        &.lesson-cell {
          &-issued {
            @include prf-lib.h3-medium;

            @include prf-lib.only-mobile {
              font-size: 16px;
            }

            &.high-percent {
              color: #7DD235;
            }
          }

          &-not-issued {
            @include prf-lib.text-default-medium;
            color: prf-lib.$text-tertiary;

            @include prf-lib.only-mobile {
              font-size: 14px;
            }
          }
        }
      }

      &.mine-class-row {
        td {
          &:first-child {
            &::before {
              content: '';
              width: 4px;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background-color: #007AFF;
            }
          }
        }
      }

      @include prf-lib.break-desktop {
        &:hover {
          .class-cell {
            .class-name,
            .teacher-name {
              color: #0072EE;
            }
          }
        }
      }
    }
  }
}
