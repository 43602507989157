$color-white: #fff;
$color-light-purple: #9375fb;
$color-purple: #7852fb;
$color-lightpurple: #8d60ec;
$color-black: #000;
$color-black1: #333;
$color-black2: #353535;
$color-orange: #ff5722;
$color-grassgreen: #36ca75;
$color-pastel: #13e5db;
$color-pink: #e74daa;
$color-blue: #42a2ff;

/* gradients range buttons */
$background-color: rgb(243, 243, 243);
$red-gradient: linear-gradient(90deg, rgba(248, 77, 86, 1) 0%, rgba(243, 90, 85, 1) 48%, rgba(239, 102, 84, 1) 100%);
$red-button: rgb(248, 77, 86);
$red-orange-gradient: linear-gradient(90deg, rgba(239, 99, 81, 1) 0%, rgba(234, 114, 80, 1) 48%, rgba(227, 129, 78, 1) 100%);
$red-orange-button: rgb(239, 99, 81);
$orange-gradient: linear-gradient(90deg, rgba(245, 134, 54, 1) 0%, rgba(239, 149, 51, 1) 48%, rgba(232, 163, 48, 1) 100%);
$orange-button: rgb(245, 134, 54);
$yellow-gradient: linear-gradient(90deg, rgba(202, 205, 38, 1) 0%, rgba(210, 197, 42, 1) 48%, rgba(217, 189, 45, 1) 100%);
$yellow-button: rgb(202, 205, 38);
$green-gradient: linear-gradient(90deg, rgba(171, 192, 49, 1) 0%, rgba(164, 201, 47, 1) 48%, rgba(154, 211, 43, 1) 100%);
$green-button: rgb(171, 192, 49);
