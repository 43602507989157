/* https://www.figma.com/file/WAVEDfY1nZuRpUFl9ZpCwQ/%F0%9F%9A%A7-tokens-design-system-%E2%86%92-In-progress?type=design&node-id=1-387&mode=design&t=f56vpD3loACpybJg-4 */

$bg-primary: var(--bg-primary, #fff);
$bg-primary-hover: var(--bg-primary-hover, #fafafa);
$bg-primary-active: var(--bg-primary-active, #f5f5f5);

$bg-primary-talent: var(--bg-primary-talent, #F3F8FE);

$bg-disabled: var(--bg-disabled, #eaeaea);

$bg-secondary: var(--bg-secondary, #f5f5f6);
$bg-secondary-hover: var(--bg-secondary-hover, #f0f0f1);
$bg-secondary-active: var(--bg-secondary-active, #ebebec);

$bg-profilum-primary: var(--bg-profilum-primary, #3d5adf);
$bg-profilum-primary-hover: var(--bg-profilum-primary-hover, #304cc8);
$bg-profilum-primary-active: var(--bg-profilum-primary-active, #2a41ab);

$bg-redesign-profilum-primary: var(--bg-redesign-profilum-primary, #007AFF);
$bg-redesign-profilum-primary-hover: var(--bg-redesign-profilum-primary-hover, #0072EE);
$bg-redesign-profilum-primary-active: var(--bg-redesign-profilum-primary-active, #0066D5);

$bg-profilum-secondary: var(--bg-profilum-secondary, #eef1ff);
$bg-profilum-secondary-hover: var(--bg-profilum-secondary-hover, #e8ecfe);
$bg-profilum-secondary-active: var(--bg-profilum-secondary-active, #e0e6ff);

$bg-profilum-alternative: var(--bg-profilum-alternative, #9375fb);
$bg-mosreg-primary: var(--bg-mosreg-primary, #16adf2);
$bg-eurochem-primary: var(--bg-mosreg-primary, #005050);

$bg-positive: var(--bg-positive, #36ca75);
$bg-positive-hover: var(--bg-positive-hover, #299959);
$bg-positive-active: var(--bg-positive-active, #23814b);

$bg-redesign-positive: var(--bg-redesign-positive, #A5FE5A);

$bg-positive-light: var(--bg-positive-light, #ebfaf1);
$bg-positive-light-hover: var(--bg-positive-light-hover, #e1f0e7);
$bg-positive-light-active: var(--bg-positive-light-active, #d8e6de);

$bg-redesign-positive-light: var(--bg-redesign-positive-light, #F0FFE3);

$bg-favorites-primary: var(--bg-favorites-primary, #0cab72);
$bg-favorites-primary-hover: var(--bg-favorites-primary-hover, #0ca46d);
$bg-favorites-primary-active: var(--bg-favorites-primary-active, #0b9d69);

$bg-favorites-secondary: var(--bg-favorites-secondary, #ceeee3);
$bg-favorites-secondary-hover: var(--bg-favorites-secondary-hover, #c6e4da);
$bg-favorites-secondary-active: var(--bg-favorites-secondary-active, #bedbd1);

$bg-progress: var(--bg-progress, #02c5bc);
$bg-progress-hover: var(--bg-progress-hover, #02ada5);
$bg-progress-active: var(--bg-progress-active, #02968f);

$bg-diagram: var(--bg-diagram, #ed1e79);
$bg-diagram-hover: var(--bg-diagram-hover, #d01a6a);
$bg-diagram-active: var(--bg-diagram-active, #b4175c);

$bg-negative-primary: var(--bg-negative-primary, #f05f4c);
$bg-negative-primary-hover: var(--bg-negative-primary-hover, #e65b49);
$bg-negative-primary-active: var(--bg-negative-primary-active, #dd5746);

$bg-negative-light-primary: var(--bg-negative-light-primary, #f6dfdd);
$bg-negative-light-primary-hover: var(--bg-negative-light-primary-hover, #f3cfcb);
$bg-negative-light-primary-active: var(--bg-negative-light-primary-active, #eeccc8);

$bg-interests-primary: var(--bg-interests-primary, #3a81ed);
$bg-interests-primary-hover: var(--bg-interests-primary-hover, #2c62b4);
$bg-interests-primary-active: var(--bg-interests-primary-active, #255298);

$bg-interests-secondary: var(--bg-interests-secondary, #d4f0fd);
$bg-interests-secondary-hover: var(--bg-interests-secondary-hover, #cce6f3);
$bg-interests-secondary-active: var(--bg-interests-secondary-active, #c3dde9);

$bg-alert: var(--bg-alert, #f87c23);
$bg-alert-hover: var(--bg-alert-hover, #da6d1f);
$bg-alert-active: var(--bg-alert-active, #bc5e1b);

$bg-alert-secondary: var(--bg-alert-secondary, #f3e6cb);
$bg-alert-secondary-hover: var(--bg-alert-secondary-hover, #f3e6cb);
$bg-alert-wait-active: var(--bg-alert-secondary-active, #e9ddc2);

$bg-alert-card: var(--bg-alert-card, #ffbe18);

$bg-skills-primary: var(--bg-skills-primary, #f87c23);
$bg-skills-primary-hover: var(--bg-skills-primary-hover, #e72);
$bg-skills-primary-active: var(--bg-skills-primary-active, #e47220);

$bg-skills-secondary: var(--bg-skills-secondary, #fdf0d3);
$bg-skills-secondary-hover: var(--bg-skills-secondary-hover, #f3e6cb);
$bg-skills-secondary-active: var(--bg-skills-secondary-active, #e9ddc2);

$bg-redesign-skills-secondary: var(--bg-redesign-skills-secondary, #FFEFDC);

$bg-tab-default: var(--bg-tab-default, #e1e0e1);
$bg-tab-hover: var(--bg-tab-hover, #d9d9d8);
$bg-tab-disabled: var(--bg-tab-disabled, #f5f5f5);
