/* https://www.figma.com/file/WAVEDfY1nZuRpUFl9ZpCwQ/%F0%9F%9A%A7-tokens-design-system-%E2%86%92-In-progress?type=design&node-id=1-355&mode=design&t=QXuHCMZiSCAeJiIz-4 */

$text-primary: var(--text-primary, #353535);
$text-primary-hover: var(--text-primary-hover, #282828);
$text-primary-active: var(--text-primary-active, #222);
$text-primary-disabled: var(--text-primary-disabled, #bfbfbf);

$text-secondary: var(--text-secondary, #5d5d5d);
$text-secondary-hover: var(--text-secondary-hover, #474747);
$text-secondary-active: var(--text-secondary-active, #3b3b3b);

$text-tertiary: var(--text-tertiary, #868686);
$text-tertiary-hover: var(--text-tertiary-hover, #666);
$text-tertiary-active: var(--text-tertiary-active, #565656);

$text-primary-inverse: var(--text-primary-inverse, #fff);
$text-primary-inverse-hover: var(--text-primary-inverse-hover, #f5f5f5);
$text-primary-inverse-active: var(--text-primary-inverse-active, #ebebeb);

$text-profilum: var(--text-profilum, #3d5adf);
$text-profilum-hover: var(--text-profilum-hover, #304cc8);
$text-profilum-active: var(--text-profilum-active, #2a41ab);

$text-profilum-redesigned: var(--text-profilum-redesigned, #007AFF);

$text-positive: var(--text-positive, #36ca75);
$text-positive-hover: var(--text-positive-hover, #299959);
$text-positive-active: var(--text-positive-active, #23814b);

$text-negative: var(--text-negative, #f05f4c);
$text-negative-hover: var(--text-negative-hover, #b6483a);
$text-negative-active: var(--text-negative-active, #9a3d31);

$text-favorites: var(--text-favorites, #0cab72);
$text-favorites-hover: var(--text-favorites-hover, #098257);
$text-favorites-active: var(--text-favorites-active, #086d49);

$text-interests: var(--text-interests, #3a81ed);
$text-interests-hover: var(--text-interests-hover, #2c62b4);
$text-interests-active: var(--text-interests-active, #255298);

$text-skills: var(--text-skills, #f87c23);
$text-skills-hover: var(--text-skills-hover, #bc5e1b);
$text-skills-active: var(--text-skills-active, #9f4f16);
// -----------------------------------------------------------
$text-profilum-secondary: var(--text-profilum-secondary, #9375fb);
$text-start-page-decorate: var(--text-start-page-decorate,rgba(255, 229, 114, 0.8))
