@use 'libs/profilum-components/src/lib/styles/styles' as prf-lib;
@use '../variables' as b2g-background-colors;

@mixin auth-form__component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: auto;
  min-height: var(--prf-app-height, 100vh);
  background-color: b2g-background-colors.$bg-page;

  @include prf-lib.break-mobile {
    min-height: var(--prf-app-height, 100vh);
  }

  @include prf-lib.break-tablet-lte {
    prf-footer {
      display: none;
    }
  }
}

.auth-form__wrapper {
  display: flex;
  flex-direction: column;
  max-height: 860px;
  min-height: 510px;
  width: 100%;
  box-shadow: 2px 2px 15px rgba(53, 53, 53, 0.08);
  margin-top: prf-lib.$spacing-x15;
  border-radius: prf-lib.$radius-x6;
  background-color: prf-lib.$bg-primary;
  flex-grow: 1;
  flex-shrink: 0;

  @include prf-lib.break-tablet-lte {
    margin-top: 0;
  }

  @include prf-lib.break-desktop {
    width: 468px;
  }

  &.no-tabs {
    .auth-form__content {
      padding-top: prf-lib.$spacing-x14;

      @include prf-lib.break-desktop {
        padding: prf-lib.$spacing-x14;
        border-radius: prf-lib.$radius-x6;
      }
    }
  }
}

.auth-form__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  background-color: prf-lib.$bg-primary;
  border-radius: 0 0 prf-lib.$radius-x6 prf-lib.$radius-x6;
  padding: 0 prf-lib.$spacing-x14 prf-lib.$spacing-x14;

  @include prf-lib.break-tablet-lte {
    padding: 0 prf-lib.$spacing-x5 prf-lib.$spacing-x14;
  }

  .logo {
    margin-bottom: 28px;
  }

  form {
    width: 100%;
    margin-bottom: 4px;

    prf-input:first-child {
      margin-bottom: 22px;
    }
  }

  .buttons-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    flex-grow: 1;

    .text-center {
      text-align: center;
    }

    .additional-button-enter {
      margin-top: 25px;
    }
  }

  .held-message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;

    .text {
      @include prf-lib.text-small-medium;
    }

    a prf-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2px;
    }
  }
}

.auth-form__tabs {
  display: flex;
  width: 100%;
  border-radius: prf-lib.$radius-x6 prf-lib.$radius-x6 0 0;
  background-color: #f8f7f4;
  margin-bottom: 28px;

  .tab {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 25px 10px;
    cursor: pointer;
    text-align: center;
    background-color: #f8f7f4;
    border-radius: prf-lib.$radius-x6 prf-lib.$radius-x6 0 0;
    @include prf-lib.h3-medium;

    @include prf-lib.break-tablet-lte {
      @include prf-lib.text-default-medium;
    }

    &.active {
      background-color: prf-lib.$bg-primary;
      @include prf-lib.h3-bold;

      @include prf-lib.break-tablet-lte {
        @include prf-lib.text-default-bold;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
        background-image: url('/profilum-assets/images/icons/angle.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 20px;
        height: 20px;
        z-index: 2;
      }
    }

    &:first-child {
      &.active {
        &::after {
          right: -20px;
        }
      }

      &:not(.active) {
        border-radius: prf-lib.$radius-x6 0 0 0;
      }
    }

    &:last-child {
      &.active {
        &::after {
          left: -20px;
          background-image: url('/profilum-assets/images/icons/angle2.png');
        }
      }

      &:not(.active) {
        border-radius: 0 prf-lib.$radius-x6 0 0;
      }
    }

    @include prf-lib.break-desktop {
      &:hover {
        color: prf-lib.$text-profilum;

        &:not(.active) {
          background-color: #f4f3f0;
        }
      }
    }
  }

  @include prf-lib.break-desktop {
    &:hover {
      background-color: #f4f3f0;
    }
  }
}
