/*Avenirnextcyr*/
@font-face {
  font-family: 'Avenirnextcyr';
  src: url('./fonts/AvenirNextCyr-Regular/avenirnextcyr-regular.eot');
  src: url('./fonts/AvenirNextCyr-Regular/avenirnextcyr-regular.woff2') format('woff2'),
    url('./fonts/AvenirNextCyr-Regular/avenirnextcyr-regular.woff') format('woff'),
    url('./fonts/AvenirNextCyr-Regular/avenirnextcyr-regular.ttf') format('truetype'),
    url('./fonts/AvenirNextCyr-Regular/avenirnextcyr-regular.svg#Avenirnextcyr') format('svg'),
    url('./fonts/AvenirNextCyr-Regular/avenirnextcyr-regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenirnextcyr';
  src: url('./fonts/AvenirNextCyr-Medium/avenirnextcyr-medium.eot');
  src: url('./fonts/AvenirNextCyr-Medium/avenirnextcyr-medium.woff2') format('woff2'),
    url('./fonts/AvenirNextCyr-Medium/avenirnextcyr-medium.woff') format('woff'),
    url('./fonts/AvenirNextCyr-Medium/avenirnextcyr-medium.ttf') format('truetype'),
    url('./fonts/AvenirNextCyr-Medium/avenirnextcyr-medium.svg#Avenirnextcyr-Medium') format('svg'),
    url('./fonts/AvenirNextCyr-Medium/avenirnextcyr-medium.eot?#iefix') format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenirnextcyr';
  src: url('./fonts/AvenirNextCyr-Demi/avenirnextcyr-demi.eot');
  src: url('./fonts/AvenirNextCyr-Demi/avenirnextcyr-demi.woff2') format('woff2'),
    url('./fonts/AvenirNextCyr-Demi/avenirnextcyr-demi.woff') format('woff'),
    url('./fonts/AvenirNextCyr-Demi/avenirnextcyr-demi.ttf') format('truetype'),
    url('./fonts/AvenirNextCyr-Demi/avenirnextcyr-demi.svg#Avenirnextcyr-Demi') format('svg'),
    url('./fonts/AvenirNextCyr-Demi/avenirnextcyr-demi.eot?#iefix') format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenirnextcyr';
  src: url('./fonts/AvenirNextCyr-Bold/avenirnextcyr-bold.eot');
  src: url('./fonts/AvenirNextCyr-Bold/avenirnextcyr-bold.woff2') format('woff2'),
    url('./fonts/AvenirNextCyr-Bold/avenirnextcyr-bold.woff') format('woff'),
    url('./fonts/AvenirNextCyr-Bold/avenirnextcyr-bold.ttf') format('truetype'),
    url('./fonts/AvenirNextCyr-Bold/avenirnextcyr-bold.svg#Avenirnextcyr-Bold') format('svg'),
    url('./fonts/AvenirNextCyr-Bold/avenirnextcyr-bold.eot?#iefix') format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}

/* -------------------------------ALSSchlangesans------------------------------------------- */
@font-face {
  font-family: ALSSchlangesans;
  src: url('./fonts/ALSSchlangesans/ALSSchlangesans.eot');
  src: url('./fonts/ALSSchlangesans/ALSSchlangesans.woff2') format('woff2'),
    url('./fonts/ALSSchlangesans/ALSSchlangesans.woff') format('woff'),
    url('./fonts/ALSSchlangesans/ALSSchlangesans.ttf') format('truetype'),
    url('./fonts/ALSSchlangesans/ALSSchlangesans.svg#ALSSchlangesans') format('svg'),
    url('./fonts/ALSSchlangesans/ALSSchlangesans.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ALSSchlangesans;
  src: url('./fonts/ALSSchlangesans-Bold/ALSSchlangesans-Bold.eot');
  src: url('./fonts/ALSSchlangesans-Bold/ALSSchlangesans-Bold.woff2') format('woff2'),
    url('./fonts/ALSSchlangesans-Bold/ALSSchlangesans-Bold.woff') format('woff'),
    url('./fonts/ALSSchlangesans-Bold/ALSSchlangesans-Bold.ttf') format('truetype'),
    url('./fonts/ALSSchlangesans-Bold/ALSSchlangesans-Bold.svg#ALSSchlangesans-Bold') format('svg'),
    url('./fonts/ALSSchlangesans-Bold/ALSSchlangesans-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
}
/* ------------------------------------------------------------------------------- */

/* -----------------------------------Intro Bold (for BVB)------------------------------------ */
@font-face {
  font-family: IntroBold;
  src: url('./fonts/Intro-Bold/intro_bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* -----------------------------------Mulish Medium------------------------------------ */
@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-Medium/Mulish-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-Medium/Mulish-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

/* -----------------------------------Mulish Light------------------------------------ */
@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-Light/Mulish-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-Light/Mulish-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-Light/Mulish-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-Light/Mulish-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

/* -----------------------------------Mulish Bold------------------------------------ */
@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-Bold/Mulish-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-Bold/Mulish-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-Bold/Mulish-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-Bold/Mulish-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

/* -----------------------------------Mulish Regular------------------------------------ */
@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-Regular/Mulish-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-Regular/Mulish-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

/* -----------------------------------Mulish SemiBold------------------------------------ */
@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-SemiBold/Mulish-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-SemiBold/Mulish-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

/* -----------------------------------Mulish-Black------------------------------------ */
@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-Black/Mulish-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-Black/Mulish-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

/* -----------------------------------Inter Light------------------------------------ */
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Light/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Light/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

/* -----------------------------------Inter Bold------------------------------------ */
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Bold/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Bold/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

/* -----------------------------------Inter Regular------------------------------------ */
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Regular/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* -----------------------------------Inter SemiBold------------------------------------ */
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Semibold/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* -----------------------------------Inter-Black------------------------------------ */
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Black/Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

/* -----------------------------------Inter-Thin------------------------------------ */
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Thin/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

/* -----------------------------------CoFo Sans------------------------------------ */
@font-face {
  font-family: 'CoFo Sans';
  src: url('./fonts/CofoSans-Regular/CoFo_Sans-Regular.eot');
  src: url('./fonts/CofoSans-Regular/CoFo_Sans-Regular.woff2') format('woff2'),
  url('./fonts/CofoSans-Regular/CoFo_Sans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CoFo Sans';
  src: url('./fonts/CofoSans-Regular/CoFo_Sans-Regular_Italic.eot');
  src: url('./fonts/CofoSans-Regular/CoFo_Sans-Regular_Italic.woff2') format('woff2'),
  url('./fonts/CofoSans-Regular/CoFo_Sans-Regular_Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'CoFo Sans';
  src: url('./fonts/CofoSans-Bold/CoFo_Sans-Bold.eot');
  src: url('./fonts/CofoSans-Bold/CoFo_Sans-Bold.woff2') format('woff2'),
  url('./fonts/CofoSans-Bold/CoFo_Sans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'CoFo Sans';
  src: url('./fonts/CofoSans-Black/CoFo_Sans-Black.eot');
  src: url('./fonts/CofoSans-Black/CoFo_Sans-Black.woff2') format('woff2'),
  url('./fonts/CofoSans-Black/CoFo_Sans-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'CoFo Sans';
  src: url('./fonts/CofoSans-Medium/CoFo_Sans-Medium.eot');
  src: url('./fonts/CofoSans-Medium/CoFo_Sans-Medium.woff2') format('woff2'),
  url('./fonts/CofoSans-Medium/CoFo_Sans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
