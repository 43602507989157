@use 'libs/profilum-components/src/lib/styles/styles' as prf-lib;

body {
  background-color: prf-lib.$bg-primary;
  font-family: Avenirnextcyr, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: prf-lib.$text-primary;
  min-height: 100%;
  overflow: auto;

  &.fixed {
    position: fixed;
    overflow: hidden;
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.content-container {
  display: flex;
  max-width: 1092px;
  padding: 0 prf-lib.$spacing-x4;
  width: 100%;
  margin: 0 auto;

  &-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: prf-lib.$spacing-x12;
    width: 100%;

    @include prf-lib.break-mobile {
      padding-top: prf-lib.$spacing-x6;
    }

    &.no-padding {
      padding-top: 0;
    }
  }
}

.content-section {
  display: block;
  margin-bottom: prf-lib.$spacing-x10;

  &:last-child {
    margin-bottom: 0;
  }

  @include prf-lib.only-mobile {
    margin-bottom: prf-lib.$spacing-x8;
  }
}
