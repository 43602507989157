/* https://www.figma.com/file/WAVEDfY1nZuRpUFl9ZpCwQ/%F0%9F%9A%A7-tokens-design-system-%E2%86%92-In-progress?type=design&node-id=1-3331&mode=design&t=QIKahs30gPKsqcEE-4 */
@use '../colors/colors' as colors;
@use '../base/variables' as variables;
@use '../functions/functions' as functions;

@mixin h1-default {
  color: colors.$text-primary;
  font-family: variables.$avenir-next;
  font-size: functions.pixels-to-rem(35);
  font-style: normal;
  line-height: 40px;
}

@mixin h1-bold {
  @include h1-default;
  font-weight: 700;
}

@mixin h1-demibold {
  @include h1-default;
  font-weight: 600;
}

@mixin h1-medium {
  @include h1-default;
  font-weight: 500;
}

@mixin h2-default {
  color: colors.$text-primary;
  font-family: variables.$avenir-next;
  font-size: functions.pixels-to-rem(24);
  font-style: normal;
  line-height: 28px;
}

@mixin h2-bold {
  @include h2-default;
  font-weight: 700;
}

@mixin h2-demibold {
  @include h2-default;
  font-weight: 600;
}

@mixin h2-medium {
  @include h2-default;
  font-weight: 500;
}

@mixin h3-default {
  color: colors.$text-primary;
  font-family: variables.$avenir-next;
  font-size: functions.pixels-to-rem(20);
  font-style: normal;
  line-height: 28px;
}

@mixin h3-bold {
  @include h3-default;
  font-weight: 700;
}

@mixin h3-demibold {
  @include h3-default;
  font-weight: 600;
}

@mixin h3-medium {
  @include h3-default;
  font-weight: 500;
}
