@use 'libs/profilum-components/src/lib/styles/styles' as prf-lib;
@use '../variables/colors' as b2g-variables;

h1,
h2,
h3,
h4{
  margin:0;
}

p{
  margin: 0;
}

a,
.link{
  color: b2g-variables.$link-color;
  text-decoration: none;
  cursor: pointer;

  @include prf-lib.break-desktop{
    &:hover{
      color: b2g-variables.$link-color-hover;
    }
  }

  &.blue-theme{
    color: b2g-variables.$link-color-hover;
  }
}

@mixin link-with-hover {
  color: b2g-variables.$link-color;
  text-decoration: none;
  cursor: pointer;

  @include prf-lib.break-desktop{
    &:hover{
      color: b2g-variables.$link-color-hover;
    }
  }
}

@mixin link {
  color: b2g-variables.$link-color-hover;
  text-decoration: none;
  cursor: pointer;
}

.page-title {
  @include prf-lib.h1-medium;
  margin-bottom: prf-lib.$spacing-x18;

  @include prf-lib.break-tablet-lte {
    margin-bottom: prf-lib.$spacing-x14;
  }

  @include prf-lib.break-mobile {
    margin-bottom: prf-lib.$spacing-x8;
  }
}

.page-subtitle {
  @include prf-lib.h2-medium;
  display: flex;
  align-items: center;
  margin-bottom: prf-lib.$spacing-x6;

  .subtitle-icon {
    flex-shrink: 0;
    margin-left: prf-lib.$spacing-x4;
    width: 20px;
    height: 20px;
  }

  @include prf-lib.break-mobile {
    margin-bottom: prf-lib.$spacing-x5;
  }
}

.title-wrapper,
.subtitle-wrapper {
  display: flex;
  align-items: center;
}

.title-wrapper {
  margin-bottom: prf-lib.$spacing-x6;
  gap: prf-lib.$spacing-x2;

  .title {
    @include prf-lib.h1-medium;

    @include prf-lib.break-mobile {
      font-size: 28px;
    }
  }

  .icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
}

.subtitle-wrapper {
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: prf-lib.$spacing-x8;
  gap: prf-lib.$spacing-x4;

  &.with-icon {
    justify-content: flex-start;
    flex-wrap: nowrap;
  }

  @include prf-lib.break-mobile {
    margin-bottom: prf-lib.$spacing-x5;
  }

  .subtitle {
    @include prf-lib.h2-medium;
  }

  .subtitle-icon {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }

  prf-button {
    min-width: 272px;

    @include prf-lib.break-mobile {
      min-width: auto;
      width: 100%;
    }
  }
}
