/* https://www.figma.com/file/WAVEDfY1nZuRpUFl9ZpCwQ/%F0%9F%9A%A7-tokens-design-system-%E2%86%92-In-progress?type=design&node-id=1-290&mode=design&t=f56vpD3loACpybJg-4 */

$profession-agroindustry: var(--profession-agroindustry, #04404e);

$profession-construction: var(--profession-construction, #c5f2fc);

$profession-security: var(--profession-security, #b35956);

$profession-management: var(--profession-management, #6643ff);

$profession-govervment: var(--profession-govervment, #ffebad);

$profession-mining: var(--profession-mining, #a39e00);

$profession-medicine: var(--profession-medicine, #ffc71f);

$profession-it: var(--profession-it, #4bf7f7);

$profession-space: var(--profession-space, #2200B8);

$profession-fashion: var(--profession-fashion, #fcb1e4);

$profession-art: var(--profession-art, #f7d1b5);

$profession-timber: var(--profession-timber, #007a7a);

$profession-engineering: var(--profession-engineering, #8a0560);

$profession-metallurgy: var(--profession-metallurgy, #d6d6d6);

$profession-science: var(--profession-science, #bcadff);

$profession-food: var(--profession-food, #e5c7c7);

$profession-silicate: var(--profession-silicate, #622e2d);

$profession-media: var(--profession-media, #fffcad);

$profession-sport: var(--profession-sport, #14ccf5);

$profession-oil: var(--profession-oil, #a37a00);

$profession-trade: var(--profession-trade, #eb6ec3);

$profession-logistics: var(--profession-logistics, #6e00b8);

$profession-tourism: var(--profession-tourism, #fff500);

$profession-finance: var(--profession-finance, #a64f11);

$profession-chemistry: var(--profession-chemistry, #deadff);

$profession-electronics: var(--profession-electronics, #5c5c5c);

$profession-law: var(--profession-law, #f1aa77);
