/* https://www.figma.com/file/WAVEDfY1nZuRpUFl9ZpCwQ/%F0%9F%9A%A7-tokens-design-system-%E2%86%92-In-progress?type=design&node-id=1-435&mode=design&t=f56vpD3loACpybJg-4 */

$bg-gradient-profilum-primary: var(
  --bg-gradient-profilum-primary,
  (
    linear-gradient(297deg, rgba(0, 71, 255, 0.4) 0%, rgba(0, 71, 255, 0) 100%),
    linear-gradient(153deg, #7953ff 0%, rgba(121, 83, 255, 0) 100%),
    #fff
  )
);
$bg-gradient-profilum-primary-hover: var(
  --bg-gradient-profilum-primary-hover,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
    linear-gradient(297deg, rgba(0, 71, 255, 0.4) 0%, rgba(0, 71, 255, 0) 100%),
    linear-gradient(153deg, #7953ff 0%, rgba(121, 83, 255, 0) 100%),
    #fff
  )
);
$bg-gradient-profilum-primary-active: var(
  --bg-gradient-profilum-primary-active,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%),
    linear-gradient(297deg, rgba(0, 71, 255, 0.4) 0%, rgba(0, 71, 255, 0) 100%),
    linear-gradient(153deg, #7953ff 0%, rgba(121, 83, 255, 0) 100%),
    #fff
  )
);

$bg-gradient-profilum-secondary: var(
  --bg-gradient-profilum-secondary,
  (
    linear-gradient(349deg, rgba(0, 71, 255, 0.4) 0%, rgba(0, 71, 255, 0.08) 100%),
    linear-gradient(172deg, #7953ff 0%, rgba(121, 83, 255, 0.16) 100%),
    #fff
  )
);
$bg-gradient-profilum-secondary-hover: var(
  --bg-gradient-profilum-secondary-hover,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
    linear-gradient(349deg, rgba(0, 71, 255, 0.4) 0%, rgba(0, 71, 255, 0.08) 100%),
    linear-gradient(172deg, #7953ff 0%, rgba(121, 83, 255, 0.16) 100%),
    #fff
  )
);
$bg-gradient-profilum-secondary-active: var(
  --bg-gradient-profilum-secondary-active,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%),
    linear-gradient(349deg, rgba(0, 71, 255, 0.4) 0%, rgba(0, 71, 255, 0.08) 100%),
    linear-gradient(172deg, #7953ff 0%, rgba(121, 83, 255, 0.16) 100%),
    #fff
  )
);

$bg-gradient-profilum-tertiary: var(
  --bg-gradient-profilum-tertiary,
  (
    linear-gradient(297deg, rgba(0, 71, 255, 0.06) 0%, rgba(0, 71, 255, 0) 100%),
    linear-gradient(153deg, rgba(106, 82, 255, 0.2) 0%, rgba(106, 82, 255, 0) 100%),
    #fff
  )
);
$bg-gradient-profilum-tertiary-hover: var(
  --bg-gradient-profilum-tertiary-hover,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
    linear-gradient(297deg, rgba(0, 71, 255, 0.06) 0%, rgba(0, 71, 255, 0) 100%),
    linear-gradient(153deg, rgba(106, 82, 255, 0.2) 0%, rgba(106, 82, 255, 0) 100%),
    #fff
  )
);
$bg-gradient-profilum-tertiary-active: var(
  --bg-gradient-profilum-tertiary-active,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%),
    linear-gradient(297deg, rgba(0, 71, 255, 0.06) 0%, rgba(0, 71, 255, 0) 100%),
    linear-gradient(153deg, rgba(106, 82, 255, 0.2) 0%, rgba(106, 82, 255, 0) 100%),
    #fff
  )
);

$bg-gradient-profilum-quaternary: var(
  --bg-gradient-profilum-quaternary,
  (
    linear-gradient(153deg, rgba(106, 82, 255, 0.04) 0%, rgba(106, 82, 255, 0) 100%),
    linear-gradient(135deg, rgba(0, 71, 255, 0.04) 0%, rgba(0, 71, 255, 0) 100%),
    #fff
  )
);
$bg-gradient-profilum-quaternary-hover: var(
  --bg-gradient-profilum-quaternary-hover,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.04) 100%),
    linear-gradient(153deg, rgba(106, 82, 255, 0.04) 0%, rgba(106, 82, 255, 0) 100%),
    linear-gradient(135deg, rgba(0, 71, 255, 0.04) 0%, rgba(0, 71, 255, 0) 100%),
    #fff
  )
);
$bg-gradient-profilum-quaternary-active: var(
  --bg-gradient-profilum-quaternary-active,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%),
    linear-gradient(153deg, rgba(106, 82, 255, 0.04) 0%, rgba(106, 82, 255, 0) 100%),
    linear-gradient(135deg, rgba(0, 71, 255, 0.04) 0%, rgba(0, 71, 255, 0) 100%),
    #fff
  )
);

$bg-gradient-favorites-primary: var(
  --bg-gradient-favorites-primary,
  (
    linear-gradient(297deg, rgba(70, 192, 185, 0.4) 0%, rgba(206, 238, 227, 0) 100%),
    linear-gradient(153deg, #0cab72 0%, rgba(12, 171, 114, 0) 100%),
    #fff
  )
);
$bg-gradient-favorites-primary-hover: var(
  --bg-gradient-favorites-primary-hover,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
    linear-gradient(297deg, rgba(70, 192, 185, 0.4) 0%, rgba(206, 238, 227, 0) 100%),
    linear-gradient(153deg, #0cab72 0%, rgba(12, 171, 114, 0) 100%),
    #fff
  )
);
$bg-gradient-favorites-primary-active: var(
  --bg-gradient-favorites-primary-active,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%),
    linear-gradient(297deg, rgba(70, 192, 185, 0.4) 0%, rgba(206, 238, 227, 0) 100%),
    linear-gradient(153deg, #0cab72 0%, rgba(12, 171, 114, 0) 100%),
    #fff
  )
);

$bg-gradient-favorites-secondary: var(
  --bg-gradient-favorites-secondary,
  (
    linear-gradient(349deg, rgba(206, 238, 227, 0.2) 0%, rgba(206, 238, 227, 0) 100%),
    linear-gradient(172deg, #009761 0%, rgba(12, 171, 114, 0) 100%),
    #fff
  )
);
$bg-gradient-favorites-secondary-hover: var(
  --bg-gradient-favorites-secondary-hover,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
    linear-gradient(349deg, rgba(206, 238, 227, 0.2) 0%, rgba(206, 238, 227, 0) 100%),
    linear-gradient(172deg, #009761 0%, rgba(12, 171, 114, 0) 100%),
    #fff
  )
);
$bg-gradient-favorites-secondary-active: var(
  --bg-gradient-favorites-secondary-active,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%),
    linear-gradient(349deg, rgba(206, 238, 227, 0.2) 0%, rgba(206, 238, 227, 0) 100%),
    linear-gradient(172deg, #009761 0%, rgba(12, 171, 114, 0) 100%),
    #fff
  )
);

$bg-gradient-favorites-tertiary: var(
  --bg-gradient-favorites-tertiary,
  (
    linear-gradient(349deg, rgba(206, 238, 227, 0.2) 0%, rgba(206, 238, 227, 0) 100%),
    linear-gradient(172deg, rgba(0, 151, 97, 0.2) 0%, rgba(12, 171, 114, 0) 100%),
    #fff
  )
);
$bg-gradient-favorites-tertiary-hover: var(
  --bg-gradient-favorites-tertiary-hover,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.06) 100%),
    linear-gradient(349deg, rgba(206, 238, 227, 0.2) 0%, rgba(206, 238, 227, 0) 100%),
    linear-gradient(172deg, rgba(0, 151, 97, 0.2) 0%, rgba(12, 171, 114, 0) 100%),
    #fff
  )
);
$bg-gradient-favorites-tertiary-active: var(
  --bg-gradient-favorites-tertiary-active,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
    linear-gradient(349deg, rgba(206, 238, 227, 0.2) 0%, rgba(206, 238, 227, 0) 100%),
    linear-gradient(172deg, rgba(0, 151, 97, 0.2) 0%, rgba(12, 171, 114, 0) 100%),
    #fff
  )
);

$bg-gradient-negative: var(
  --bg-gradient-negative,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
    linear-gradient(297deg, rgba(240, 95, 76, 0.4) 0%, rgba(240, 95, 76, 0) 100%),
    linear-gradient(153deg, #de4531 0%, rgba(222, 69, 49, 0) 100%),
    #fff
  )
);
$bg-gradient-negative-hover: var(
  --bg-gradient-negative-hover,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
    linear-gradient(297deg, rgba(240, 95, 76, 0.4) 0%, rgba(240, 95, 76, 0) 100%),
    linear-gradient(153deg, #de4531 0%, rgba(222, 69, 49, 0) 100%),
    #fff
  )
);
$bg-gradient-negative-active: var(
  --bg-gradient-negative-active,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%),
    linear-gradient(297deg, rgba(240, 95, 76, 0.4) 0%, rgba(240, 95, 76, 0) 100%),
    linear-gradient(153deg, #de4531 0%, rgba(222, 69, 49, 0) 100%),
    #fff
  )
);

$bg-gradient-interests-primary: var(
  --bg-gradient-interests-primary,
  (
    linear-gradient(297deg, rgba(0, 240, 255, 0.2) 0%, rgba(0, 240, 255, 0) 100%),
    linear-gradient(153deg, #3a81ed 0%, rgba(58, 129, 237, 0) 100%),
    #fff
  )
);
$bg-gradient-interests-primary-hover: var(
  --bg-gradient-interests-primary-hover,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
    linear-gradient(297deg, rgba(0, 240, 255, 0.2) 0%, rgba(0, 240, 255, 0) 100%),
    linear-gradient(153deg, #3a81ed 0%, rgba(58, 129, 237, 0) 100%),
    #fff
  )
);
$bg-gradient-interests-primary-active: var(
  --bg-gradient-interests-primary-active,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%),
    linear-gradient(297deg, rgba(0, 240, 255, 0.2) 0%, rgba(0, 240, 255, 0) 100%),
    linear-gradient(153deg, #3a81ed 0%, rgba(58, 129, 237, 0) 100%),
    #fff
  )
);

$bg-gradient-interests-secondary: var(
  --bg-gradient-interests-secondary,
  (
    linear-gradient(349deg, rgba(0, 240, 255, 0.2) 0%, rgba(0, 240, 255, 0) 100%),
    linear-gradient(172deg, #3a81ed 0%, rgba(58, 129, 237, 0) 100%),
    #fff
  )
);
$bg-gradient-interests-secondary-hover: var(
  --bg-gradient-interests-secondary-hover,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
    linear-gradient(349deg, rgba(0, 240, 255, 0.2) 0%, rgba(0, 240, 255, 0) 100%),
    linear-gradient(172deg, #3a81ed 0%, rgba(58, 129, 237, 0) 100%),
    #fff
  )
);
$bg-gradient-interests-secondary-active: var(
  --bg-gradient-interests-secondary-active,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%),
    linear-gradient(349deg, rgba(0, 240, 255, 0.2) 0%, rgba(0, 240, 255, 0) 100%),
    linear-gradient(172deg, #3a81ed 0%, rgba(58, 129, 237, 0) 100%),
    #fff
  )
);

$bg-gradient-interests-tertiary: var(
  --bg-gradient-interests-tertiary,
  (
    linear-gradient(349deg, rgba(58, 129, 237, 0.12) 0%, rgba(58, 129, 237, 0) 100%),
    linear-gradient(172deg, rgba(0, 240, 255, 0.12) 0%, rgba(0, 240, 255, 0) 100%),
    #fff
  )
);
$bg-gradient-interests-tertiary-hover: var(
  --bg-gradient-interests-tertiary-hover,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.06) 100%),
    linear-gradient(349deg, rgba(58, 129, 237, 0.12) 0%, rgba(58, 129, 237, 0) 100%),
    linear-gradient(172deg, rgba(0, 240, 255, 0.12) 0%, rgba(0, 240, 255, 0) 100%),
    #fff
  )
);
$bg-gradient-interests-tertiary-active: var(
  --bg-gradient-interests-tertiary-active,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
    linear-gradient(349deg, rgba(58, 129, 237, 0.12) 0%, rgba(58, 129, 237, 0) 100%),
    linear-gradient(172deg, rgba(0, 240, 255, 0.12) 0%, rgba(0, 240, 255, 0) 100%),
    #fff
  )
);

$bg-gradient-skills-primary: var(
  --bg-gradient-skills-primary,
  (
    linear-gradient(297deg, rgba(250, 224, 87, 0.6) 0%, rgba(250, 224, 87, 0) 100%),
    linear-gradient(153deg, #f87c23 0%, rgba(248, 124, 35, 0.4) 100%),
    #fff
  )
);
$bg-gradient-skills-primary-hover: var(
  --bg-gradient-skills-primary-hover,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
    linear-gradient(297deg, rgba(250, 224, 87, 0.6) 0%, rgba(250, 224, 87, 0) 100%),
    linear-gradient(153deg, #f87c23 0%, rgba(248, 124, 35, 0.4) 100%),
    #fff
  )
);
$bg-gradient-skills-primary-active: var(
  --bg-gradient-skills-primary-active,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%),
    linear-gradient(297deg, rgba(250, 224, 87, 0.6) 0%, rgba(250, 224, 87, 0) 100%),
    linear-gradient(153deg, #f87c23 0%, rgba(248, 124, 35, 0.4) 100%),
    #fff
  )
);

$bg-gradient-skills-secondary: var(
  --bg-gradient-skills-secondary,
  (
    linear-gradient(169deg, #e66000 0%, rgba(230, 96, 0, 0.32) 100%),
    linear-gradient(352deg, #fae057 0%, rgba(250, 224, 87, 0.4) 100%),
    #fff
  )
);
$bg-gradient-skills-secondary-hover: var(
  --bg-gradient-skills-secondary-hover,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
    linear-gradient(169deg, #e66000 0%, rgba(230, 96, 0, 0.32) 100%),
    linear-gradient(352deg, #fae057 0%, rgba(250, 224, 87, 0.4) 100%),
    #fff
  )
);
$bg-gradient-skills-secondary-active: var(
  --bg-gradient-skills-secondary-active,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%),
    linear-gradient(169deg, #e66000 0%, rgba(230, 96, 0, 0.32) 100%),
    linear-gradient(352deg, #fae057 0%, rgba(250, 224, 87, 0.4) 100%),
    #fff
  )
);

$bg-gradient-skills-tertiary: var(
  --bg-gradient-skills-tertiary,
  (
    linear-gradient(169deg, rgba(248, 124, 35, 0.08) 0%, rgba(248, 124, 35, 0.02) 100%),
    linear-gradient(352deg, rgba(250, 224, 87, 0.08) 0%, rgba(250, 224, 87, 0.03) 100%),
    #fcfbf8
  )
);
$bg-gradient-skills-tertiary-hover: var(
  --bg-gradient-skills-tertiary-hover,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.06) 100%),
    linear-gradient(169deg, rgba(248, 124, 35, 0.08) 0%, rgba(248, 124, 35, 0.02) 100%),
    linear-gradient(352deg, rgba(250, 224, 87, 0.08) 0%, rgba(250, 224, 87, 0.03) 100%),
    #fcfbf8
  )
);
$bg-gradient-skills-tertiary-active: var(
  --bg-gradient-skills-tertiary-active,
  (
    linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%),
    linear-gradient(169deg, rgba(248, 124, 35, 0.08) 0%, rgba(248, 124, 35, 0.02) 100%),
    linear-gradient(352deg, rgba(250, 224, 87, 0.08) 0%, rgba(250, 224, 87, 0.03) 100%),
    #fcfbf8
  )
);

$normal-background-gradient: var(
--normal-background-gradient,
      (
        linear-gradient(91deg, #14CE75 0.7%, #5A9CFF 57.65%, #B38EFF 99.22%)
      )
)
