@use '../../styles/styles' as prf-lib;

:root{
  --custom-scrollbar-width: 10px;
  --custom-scrollbar-content-offset: 20px;
  --custom-scrollbar-border-radius: 10px;
}

.custom-scrollbar{
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: var(--custom-scrollbar-content-offset);
  padding-right: var(--custom-scrollbar-content-offset);

  &::-webkit-scrollbar {
    width: var(--custom-scrollbar-width);

    &:horizontal {
      height: var(--custom-scrollbar-width);
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: var(--custom-scrollbar-border-radius);
    background: prf-lib.$bg-gradient-profilum-secondary;
  }

  @-moz-document url-prefix() {
    scrollbar-color: prf-lib.$color-light-purple transparent;
    scrollbar-width: thin;
  }

  &.light-purple{
    &::-webkit-scrollbar-thumb {
      background: prf-lib.$color-light-purple;
    }
  }

  &.gray{
    &::-webkit-scrollbar-thumb {
      background: prf-lib.$divider;
    }
  }

  &.horizontal{
    overflow-x: auto;
    overflow-y: hidden;
  }

  &.no-bottom-spacing{
    padding-bottom: 0;
  }

  &.no-right-spacing{
    padding-right: 0;
  }

  &.custom-styles-disabled-mobile{
    @include prf-lib.break-mobile{
      padding-bottom: 0;
      padding-right: 0;

      &::-webkit-scrollbar {
        width: 0;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background: initial;
      }

      @-moz-document url-prefix() {
        scrollbar-color: auto;
        scrollbar-width: auto;
      }
    }
  }

  &.custom-styles-disabled-desktop{
    @include prf-lib.break-desktop{
      &::-webkit-scrollbar {
        width: initial;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: initial;
        background: initial;
      }
    }
  }
}
