@use '../../styles/styles' as prf-lib;

.first-block-indent {
  margin-top: 131px;

  @include prf-lib.break-tablet-gte {
    margin-top: 152px;
  }
}

.block-bottom-indent {
  margin-bottom: 80px;

  @include prf-lib.break-tablet-gte {
    margin-bottom: 108px;
  }
}

.block-paddings {
  padding: 0 16px;

  @include prf-lib.break-tablet-gte {
    padding: 0 40px;
  }

  @include prf-lib.break-desktop-large {
    padding: 0;
  }
}

.standard-block {
  @extend .block-paddings;
  display: block;
  max-width: 1358px;
  width: 100%;
  overflow: hidden;

  @include prf-lib.break-desktop-medium-gte {
    margin-left: auto;
    margin-right: auto;
  }
}
