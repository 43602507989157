@use 'libs/profilum-components/src/lib/styles/styles' as prf-lib;

.flex-grid {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
  width: 100%;
  margin: -10px;

  &.wrap {
    @include prf-lib.break-tablet {
      flex-wrap: wrap;
    }
  }

  &.justify-content-start {
    justify-content: flex-start;
  }

  &.justify-content-center {
    justify-content: center;
  }

  &.tablet-wrap {
    @include prf-lib.break-tablet {
      flex-wrap: wrap;
    }
  }

  &.mobile-wrap {
    @include prf-lib.break-mobile {
      flex-wrap: wrap;
    }
  }

  &.desktop-column {
    @include prf-lib.break-desktop {
      flex-direction: column;
    }
  }

  &.mobile-row {
    @include prf-lib.break-mobile {
      flex-direction: row;
    }
  }

  &.column {
    flex-direction: column;
  }

  .col {
    display: flex;
    align-items: stretch;
    padding: 10px;
    width: 100%;

    &.flex-direction-column {
      flex-direction: column;
    }

    &.justify-center{
      justify-content: center;
    }

    &.justify-end{
      justify-content: flex-end;
    }

    &.w-25 {
      width: 25%;
    }

    &.w-30 {
      width: 30%;
    }

    &.w-33 {
      width: 33.333333%;
    }

    &.w-35 {
      width: 35%;
    }

    &.w-40 {
      width: 40%;
    }

    &.w-50 {
      width: 50%;
    }
  }
}

