$spacing-x1: 4px;
$spacing-x2: $spacing-x1 * 2;
$spacing-x3: $spacing-x1 * 3;
$spacing-x4: $spacing-x1 * 4;
$spacing-x5: $spacing-x1 * 5;
$spacing-x6: $spacing-x1 * 6;
$spacing-x7: $spacing-x1 * 7;
$spacing-x8: $spacing-x1 * 8;
$spacing-x9: $spacing-x1 * 9;
$spacing-x10: $spacing-x1 * 10;
$spacing-x12: $spacing-x1 * 12;
$spacing-x13: $spacing-x1 * 13;
$spacing-x14: $spacing-x1 * 14;
$spacing-x15: $spacing-x1 * 15;
$spacing-x16: $spacing-x1 * 16;
$spacing-x18: $spacing-x1 * 18;
$spacing-x20: $spacing-x1 * 20;
$spacing-x35: $spacing-x1 * 35;
