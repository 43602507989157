@use 'libs/profilum-components/src/lib/styles/styles' as prf-lib;

.prf-class-statistics-table {
  grid-template-columns: 70px 290px 191px 191px 191px 191px 191px 191px;

  @include prf-lib.only-mobile {
    grid-template-columns: 52px 175px 175px 175px 175px 175px 175px 175px;
  }

  th, td {
    align-content: center;
  }

  thead {
    th {
      text-wrap: wrap;

      &:first-child, &:nth-child(2) {
        background: #fff;
        position: -webkit-sticky;
        position: sticky;
      }


      &:first-child {
        text-align: center;
        left: 0;
      }

      &:nth-child(2) {
        border-right: 2px solid #EAEAEA;
        left: 70px;

        @include prf-lib.only-mobile {
          left: 52px;
        }

        &::after {
          content: '';
          position: absolute;
          right: -4px;
          top: 0;
          width: 4px;
          height: 100%;
          background-color: #eaeaea;
          filter: blur(2px);
        }
      }

      .current-lesson {
        color: prf-lib.$text-tertiary;

        @include prf-lib.only-mobile {
          font-size: 14px;
        }
      }
    }
  }

  tbody {
    tr {
      @include prf-lib.break-desktop {
        &:hover {
          td {
            background-color: prf-lib.$bg-primary-talent;
          }
        }
      }

      td {
        &:first-child, &:nth-child(2) {
          position: -webkit-sticky;
          position: sticky;
        }

        &:first-child {
          left: 0;
        }

        &:nth-child(2) {
          left: 70px;
          border-right: 2px solid #EAEAEA;

          @include prf-lib.only-mobile {
            left: 52px;
          }

          &::after {
            content: '';
            position: absolute;
            right: -4px;
            top: 0;
            width: 4px;
            height: 100%;
            background-color: #eaeaea;
            filter: blur(2px);
          }
        }

        &.user-name-td {
          @include prf-lib.only-mobile {
            font-size: 14px;
          }
        }

        &.user-number-td, &.user-name-td {
          align-content: center;
        }

        &.user-number-td {
          text-align: center;
        }

        &.lesson-cell {
          &-not-issued {
            @include prf-lib.text-default-medium;
            color: prf-lib.$text-tertiary;

            @include prf-lib.only-mobile {
              font-size: 14px;
            }
          }

          &-not-passed, &-passed {
            div {
              width: 20px;
              height: 20px;
              border-radius: prf-lib.$radius-x1;
            }
          }

          &-not-passed {
            div {
              background-color: prf-lib.$text-negative;
            }
          }

          &-passed {
            div {
              background-color: #7dd235;
            }
          }
        }
      }

      @include prf-lib.break-desktop {
        &:hover {
          .class-cell {
            .class-name,
            .teacher-name {
              color: #0072EE;
            }
          }
        }
      }
    }
  }
}
