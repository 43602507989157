$link-color: var(--link-color, #868686);
$link-color-hover: var(--link-color-hover, #5d7aff);
$link-color-active: var(--link-color-active, #5d7aff);

$input-text-color-disabled: var(--input-text-color-disabled, rgb(84, 84, 84));

$input-background-color-disabled: var(--input-background-color-disabled, rgba(239, 239, 239, 0.3));

$input-border-color: var(--input-border-color, #DEDEDE);
$input-border-color-active: var(--input-border-color-focused, #f2f2f2);
$input-border-color-focused: var(--input-border-color-focused, #3D5ADF);
$input-border-color-disabled: var(--input-border-color-disabled, rgba(118, 118, 118, 0.3));
$input-border-color-error: var(--input-border-color-error, #F05F4C);

$bg-page: var(--bg-page, #fcfbf8);
