@use 'libs/profilum-components/src/lib/styles/styles' as prf-lib;

.prf-users-table {
  grid-template-columns: 70px 480px 190px 320px;

  @include prf-lib.only-mobile {
    grid-template-columns: 60px minmax(220px, 1fr) minmax(185px, 1fr) minmax(160px, 1fr);
  }

  tbody {
    tr {
      cursor: pointer;

      @include prf-lib.break-desktop {
        &:hover {
          td {
            background-color: prf-lib.$bg-primary-talent;
          }
        }
      }
    }
  }

  th {
    &.user-number-th,
    &.status-th {
      text-align: center;
    }
  }

  td {
    transition: background-color .15s linear;

    &.user-number-td {
      align-content: center;
      text-align: center;
    }

    &.user-name-td {
      align-content: center;
    }

    &.status-td {
      align-content: center;

      .status {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: prf-lib.$text-secondary;
        padding: prf-lib.$spacing-x1 prf-lib.$spacing-x7 prf-lib.$spacing-x1 prf-lib.$spacing-x3;
        gap: prf-lib.$spacing-x1;
        border-radius: prf-lib.$radius-x4;
        border: 1px solid prf-lib.$divider;
        width: fit-content;
        margin: 0 auto;

        .icon {
          flex-shrink: 0;
          width: 18px;
          height: 18px;
          fill: prf-lib.$text-secondary;
        }
      }

      .invite-user-button {
        display: flex;
        justify-content: center;
      }
    }

    &.classes-td {
      .wrapper {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
