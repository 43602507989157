@use 'libs/profilum-components/src/lib/styles/styles' as prf-lib;

prf-table-b2g-new {
  display: block;
  overflow-x: auto;
  border-radius: prf-lib.$radius-x2;
  box-shadow: 2px 2px 15px 0 rgba(53, 53, 53, 0.08);

  .custom-table {
    display: grid;
    border-collapse: collapse;
    min-width: 100%;
    width: fit-content;

    thead,
    tbody,
    tr {
      display: contents;
    }

    tr:nth-child(odd) {
      td {
        background: #F5F5F5;
      }
    }

    tr:nth-child(even) {
      td {
        background: prf-lib.$bg-primary;
      }
    }

    th,
    td {
      @include prf-lib.text-default-medium;
      padding: 10px 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }

    th {
      font-weight: 700;

      .title-with-icon {
        display: flex;
        align-items: center;
        gap: prf-lib.$spacing-x2;

        .name {
          @include prf-lib.text-default-bold;

          @include prf-lib.only-mobile {
            font-size: 14px;
          }
        }

        .sort-icon {
          cursor: pointer;
          width: 20px;
          height: 20px;
          flex-shrink: 0;

          &.active-sort {
            fill: #0072EE;
          }
        }

        &.centered {
          justify-content: center;
        }
      }
    }
  }
}
