@use '../variables' as b2g-background-colors;
@use 'libs/profilum-components/src/lib/styles/styles' as prf-lib;

@mixin base-input-styles{
  height: 60px;
  width: 100%;
  border: 2px solid b2g-background-colors.$input-border-color;
  border-radius: prf-lib.$radius-x3;
  padding: 20px 16px;
  outline: none;

  &.error-field {
    border: 2px solid b2g-background-colors.$input-border-color-error;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    background-color: b2g-background-colors.$input-background-color-disabled;
    color: b2g-background-colors.$input-text-color-disabled;
    border-color: b2g-background-colors.$input-border-color-disabled;
  }

  &:active {
    border: 2px solid b2g-background-colors.$input-border-color-active;
    box-shadow: 0 0 0 0 b2g-background-colors.$input-border-color-active;
  }

  &:focus-visible {
    border: 2px solid b2g-background-colors.$input-border-color-focused;
  }
}

.field{
  position: relative;
  width: 100%;

  & label {
    position: absolute;
    z-index: 10;
    left: 20px;
    top: -13px;
    background: #fff;
    padding: 5px;
  }

  .wrapper{
    position: relative;

    .input {
      @include base-input-styles;
    }

    .icon {
      position: absolute;
      cursor: pointer;
      width: 36px;
      height: 36px;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);

      &.edit{
        background: url('/profilum-assets/images/menu/edit-icon-black.svg') no-repeat center;
      }

      &.calendar{
        background: url('/profilum-assets/images/icons/calendar.svg') no-repeat center;
      }

      &.clear{
        background: url('/profilum-assets/images/icons/delete.svg') no-repeat center;
        opacity: .3;
        transition: opacity .15s linear;

        @include prf-lib.break-desktop-small-gte{
          &:hover{
            opacity: .7;
          }
        }
      }
    }
  }
}
.error-label {
  color: b2g-background-colors.$input-border-color-error;
}
