@use '../variables' as b2g-background-colors;
@use 'libs/profilum-components/src/lib/styles/styles' as prf-lib;

.ng-select .ng-select-container {
  width: 100%;
  border: 2px solid b2g-background-colors.$input-border-color;
  border-radius: prf-lib.$radius-x3;
}

.ng-select.ng-select-single.small {
  .ng-select-container {
    height: 40px;
  }
}

.ng-select.ng-select-single .ng-select-container {
  height: 60px;
}

.ng-select.ng-select-opened > .ng-select-container {
  border-color: b2g-background-colors.$input-border-color-focused;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: b2g-background-colors.$input-border-color-focused;
  box-shadow: unset;
}

.ng-dropdown-panel {
  overflow: hidden;
  border: 2px solid b2g-background-colors.$input-border-color;
  border-top: 0;

  &.ng-select-bottom {
    margin-top: 0;
  }

  &.ng-select-top {
    border-top: 1px solid #ccc;
    margin-bottom: 0;
  }

  .ng-dropdown-panel-items {
    .ng-option{
      padding: 12px 16px;

      &.ng-option-marked {
        background-color: prf-lib.$bg-profilum-secondary-hover;
        transition: background-color .15s linear, color .15s linear;
      }
    }
  }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 50%;
  transform: translateY(-50%);
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input, .ng-select .ng-select-container .ng-value-container {
  padding-left: 16px;
}

.ng-select .ng-clear-wrapper {
  width: 30px !important;
  height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .3;
  transition: opacity .15s linear;

  @include prf-lib.break-desktop{
    &:hover{
      opacity: .7;
    }
  }
}

.ng-select .ng-clear-wrapper .ng-clear {
  font-size: 0 !important;
  width: 20px !important;
  height: 20px !important;
  background-image: url('/profilum-assets/images/icons/delete.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: 700;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked{
  color: prf-lib.$text-primary-inverse;
  background-color: prf-lib.$bg-profilum-primary-hover;
}

.ng-dropdown-panel.ng-select-bottom,
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child{
  border-bottom-right-radius: prf-lib.$radius-x3;
  border-bottom-left-radius: prf-lib.$radius-x3;
}

.ng-select .ng-clear-wrapper .ng-clear{
  color: prf-lib.$bg-negative-primary;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value{
  white-space: normal !important;
}
