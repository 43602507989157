@use 'libs/profilum-components/src/lib/styles/styles' as prf-lib;
@import 'air-datepicker/air-datepicker.css';

.air-datepicker-cell.-current-{
  color: prf-lib.$text-profilum !important;
}

.air-datepicker-cell.-selected-{
  color: prf-lib.$text-primary-inverse !important;
  background: prf-lib.$text-profilum !important;
}

.air-datepicker-body--day-name{
  color: prf-lib.$text-profilum !important;
}
